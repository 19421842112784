export const environment = {
  env: 'qa',
  api_url: 'https://api.nexteo-qa.conaset.cl',
  claveunica: {
    auth_api: 'https://api.nexteo-qa.conaset.cl',
    redirect_login: 'https://accounts.claveunica.gob.cl',
    claveunica_client_id: '0ec6d535e64743f491feb40f97a58128',
    claveunica_login_uri: 'https://nexteo-qa.conaset.cl/auth/callback',
    claveunica_logout_uri: 'https://nexteo-qa.conaset.cl/auth/logout',
  },
}
